* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}


html,
body {
    width: 100%;
    height: 100%;
    font-family: "Manrope", sans-serif;
    /* background-color: #0f0f0f; */
    /* color: white; */
    position: relative;
}

main {
    display: flex;
    flex-direction: column;
    justify-content: center;

}

nav {
    position: absolute;
    top: 0;
    width: 100%;
    padding: 2em;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;

    a {
        text-decoration: none;
        color: black;
    }
}

.hero {
    width: 100%;
    background-color: white;
    color: black;
    height: 100vh;
    padding: 5em 2em 2em 2em;
    display: flex;
    flex-direction: row;
    animation: show 0.5s ease-in-out;
    gap: 5rem;
    align-items: end;
    div {
        /* height: 100%; */
        width: 400px;
        display: flex;
        flex-direction: column;
        justify-content: end;
        align-items: end;
        animation: show 0.5s ease-in-out;

        h1 {
            font-size: 2.5rem;
            font-weight: normal;
            letter-spacing: -1.5px;
        }

        p {
            font-size: 18px;
        }
    }

    img {
        height: 100%;
        width: 60%;
        display: block;
        aspect-ratio: 1/1;
        object-fit: cover;
    }
}

.gallery {
    width: 100%;
    padding: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
}

img {
    width: 100%;
    display: block;
    aspect-ratio: 1/1;
    object-fit: cover;
}

ul {
    list-style: none;
    display: grid;
    gap: 0.5rem;
    grid-template-columns: repeat(auto-fit, minmax(20rem, 1fr));
    max-width: 100%;
    width: 100vw;
}


@media screen and (min-width: 1200px) {
    .hero div h1 {
        font-size: 4rem;
        font-weight: normal;
        letter-spacing: -1px;
    }

    .hero div p {
        font-size: 1rem;
    }
}

@media screen and (max-width: 1200px) {
    .hero {
        gap: 2rem;
    }
}

@media screen and (max-width: 800px) {
    .hero {
        justify-content: space-between;
        gap: 20px;
    }

    .hero div h1 {

        font-size: 30px;
        font-weight: normal;
        letter-spacing: -1px;
    }

    .hero div p {
        font-size: 18px;
        letter-spacing: normal;
    }
}

@media screen and (max-width: 600px) {
    .hero {
        flex-direction: column;
        align-items: baseline;
    }

    .hero {
        gap: 1rem;
        align-items: top;

        div {
            height: 100%;
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: end;

            h1 {
                font-size: 3rem;
                font-weight: normal;
                letter-spacing: -1.5px;
            }

            p {
                font-size: 20px;
                letter-spacing: normal;
            }
        }

        img {
            width: 100% !important;
        }
    }
}

@keyframes show {
    from {
        opacity: 0;
        transform: translateY(10px);
    }

    to {
        opacity: 1;
        transform: translateY(0px);
    }
}